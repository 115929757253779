import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GenericUtil } from '@worldskills/worldskills-angular-lib';
import { ResultService } from '../../services/result.service';
import { EventModel } from '../../models/event-model';
import { ResultCollection } from '../../models/result-collection';
import { ResultItem } from '../../models/result-item';
import { Skill } from '../../models/skill';
import { SkillCollection } from '../../models/skill-collection';
import { EventsService } from '../../services/event.service';

@Component({
  selector: 'app-event-results',
  templateUrl: './event-results.component.html',
  styleUrls: ['./event-results.component.css']
})
export class EventResultsComponent implements OnInit {

  event: EventModel;
  resultCollection: ResultCollection;
  skillCollection: SkillCollection;

  publish: boolean;

  constructor(public route: ActivatedRoute, private events: EventsService, private results: ResultService) { }

  ngOnInit() {
    this.publish = false;
    this.event = this.initEventModel();
    this.route.params.subscribe(
      params =>  {
        if (params.hasOwnProperty('id')) {
          const id = Number.parseInt(params.id, 10);
          this.events.get(id).subscribe(nextEvent => {
            this.event = nextEvent;
            this.events.getSkills(this.event.id).subscribe(
              nextSkills => {
                this.skillCollection = nextSkills;
                this.loadData();
              }
            );
          });
        }
      }
    );

  }

  initEventModel() {
    const eventModel: EventModel = {
      id: null,
      name: null,
      type: null,
      start_date: null,
      end_date: null,
      competition_start_date: null,
      competition_end_date: null,
      venue: null,
      town: null,
      code: null,
      country: { id: null, code: null, name: { lang_code: null, text: null } },
      utc_offset: null,
      url: null,
      description: null,
      ws_entity: { id: null, name: { lang_code: null, text: null } }
    };

    return eventModel;
  }

  loadData() {
    this.results.getResultsForEvent(this.event.id, this.publish).subscribe(
      nextResult => {
        this.resultCollection = nextResult;
      }
    );
  }

  getBestOfNations(): ResultItem[] {
    return this.resultCollection.results.filter(x => x.best_of_nation);
  }

  getBestOfNationsText() {
    const output = [];
    const bon = this.getBestOfNations().sort((x, y) => {
      const xkey = x.member.name_1058.text;
      const ykey = y.member.name_1058.text;

      if (xkey > ykey) {
        return 1;
      }

      if (xkey < ykey) {
          return -1;
      }

      return 0;
    });

    bon.forEach(result => {
      const memberName = result.member.name.text;
      let line = `${memberName}: `;
      let i = 0;
      result.competitors.forEach(competitor => {
        if (i > 0) {
          line += ', ';
        }
        line += `${competitor.first_name} ${competitor.last_name}`;
        i++;
      });
      output.push(line);
    });

    return output.join('\n');
  }

  getAlbertVidal(): ResultItem[] {
    return this.resultCollection.results.filter(x => x.albert_vidal_award);
  }

  getAlbertVidalText() {
    const output = [];
    const data = this.getAlbertVidal();
    data.forEach(item => {
      item.competitors.forEach(competitor => {
        const line = `${competitor.first_name} ${competitor.last_name}, `
        output.push(line);
      });
      output.push('');
    });

   return output.join('\n');
  }

  getResultsForSkill(skill: Skill): ResultItem[] {
    return this.resultCollection.results.filter(x => x.skill.id == skill.id && !GenericUtil.isNullOrUndefined(x.medal));
  }

  getResultsTextForSkill(skill: Skill) {
    const output = [];
    output.push(skill.name.text);
    output.push('');
    const results = this.getResultsForSkill(skill);
    const medalGroups = new Map<string, string[]>();
    // group medals
    results.forEach(result => {
      if (!GenericUtil.isNullOrUndefined(result.medal)) {
        const key = result.medal.name.text;
        if (!medalGroups.has(key)) {
          medalGroups.set(key, []);
        }
        const people = medalGroups.get(key);
        let line = '';
        result.competitors.forEach((competitor, ci) => {
            if (ci > 0) {
              line += ', ';
            }
            line += `${competitor.first_name} ${competitor.last_name}`;
        });
        if (result.competitors.length > 0) {
          line += `, ${result.member.name.text}`;
        }
        people.push(line);
        medalGroups.set(key, people);
      }
    });

    medalGroups.forEach((value, key) => {
      value.forEach(item => {
        output.push(`${key}: ${item}`);
      });

    });

    return output.join('\n');
  }

}
