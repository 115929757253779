import { HttpParams } from '@angular/common/http';

export class EventServiceParams {
  offset: number;
  limit: number;
  sort: string;
  name: string;
  // competition,general_assembly,champions_forum,conference,meeting,member_event
  type: string;
  // tslint:disable-next-line:variable-name
  ws_entity: number;
  after: string;
  before: string;

  constructor(obj?: Partial<EventServiceParams>) {
    this.offset = obj && obj.offset || 0;
    this.limit = obj && obj.limit || 10;
    this.sort = obj && obj.sort || 'start_date_desc';
    this.name = obj && obj.name || null;
    this.type = obj && obj.type || null;
    this.ws_entity = obj && obj.ws_entity || null;
    this.after = obj && obj.after || null;
    this.before = obj && obj.before || null;
  }

  public toParams(): HttpParams {
    let params = new HttpParams()
      .set('offset', this.offset.toString())
      .set('limit', this.limit.toString())
      .set('sort', this.sort);
    if (this.name) {
      params = params.set('name', this.name);
    }
    if (this.type) {
      params = params.set('type', this.type);
    }

    if (this.ws_entity) {
      params = params.set('ws_entity', this.ws_entity.toString());
    }

    if (this.after) {
      params = params.set('after', this.after);
    }

    if (this.before) {
      params = params.set('before', this.before);
    }

    return params;
  }
}
