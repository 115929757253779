import { Component, OnInit } from '@angular/core';

import { Meta } from '@angular/platform-browser';
import { NgAuthService, User, WorldskillsAngularLibService, WsiTranslateService, GenericUtil, Language, MenuItem } from '@worldskills/worldskills-angular-lib';

import { environment } from './../environments/environment';
import { AppService } from './services/app.service';
import { appConfig, oAuthConfig, httpConfig, serviceConfig } from './app.config';
import { TranslateService } from '@ngx-translate/core';
import { appRoles } from 'src/environments/environment.prod';

const menuConfig = [
  { label: 'Home', url: '/results', params: [], hidden: false, requireLogin: false, requiredRoles: [] },
  { label: 'Upload Results', url: '/admin/events', params: [], hidden: false, requireLogin: false, requiredRoles: [appRoles.admin] },
];
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  currentUser: User;
  environmentWarning = environment.environmentWarning;
  lang: Language;
  showLoginAndLogoutButtons = true;

  constructor(private app: AppService, private auth: NgAuthService, private meta: Meta, private translator: TranslateService,
              private wsiTranslate: WsiTranslateService, private wsi: WorldskillsAngularLibService) { }

  ngOnInit() {
    // check and add noindex meta tag if needed
    if (environment.noindex) {
      this.meta.addTag({ name: 'robots', content: 'noindex' });
    }
    this.configureLib();
    this.configureFontTracking();
    this.configureUser();
    this.translator.setDefaultLang('en');
    this.wsiTranslate.onLangChanged.subscribe(lang => {
      console.log(lang);
      if (GenericUtil.isNullOrUndefined(this.lang)) {
        this.lang = lang;
      } else {
        if (this.lang !== lang) {
          this.lang = lang;
          window.location.reload();
        }
      }
    });
  }

  configureLib(): void {
    this.wsi.appConfigSubject.next(appConfig);
    this.wsi.authConfigSubject.next(oAuthConfig);
    this.wsi.httpConfigSubject.next(httpConfig);
    this.wsi.serviceConfigSubject.next(serviceConfig);
  }

  configureUser(): void {
    this.app.configureUser();
    this.auth.getLoggedInUser(true).subscribe(user => {
      if (user) {
        this.currentUser = user;
      }
    }, error => {
      console.warn(error);
    });
  }

  configureFontTracking() {
    if (environment.fontTrackingCode) {
      (window as any).MTUserId = '94ee3747-9a1d-466d-acf9-bbeb8cbd7714';
      (window as any).MTFontIds = new Array();

      (window as any).MTFontIds.push('1572247'); // Frutiger® W04 45 Light
      (window as any).MTFontIds.push('1572251'); // Frutiger® W04 46 Light Italic
      (window as any).MTFontIds.push('1572255'); // Frutiger® W04 55 Roman
      (window as any).MTFontIds.push('1572259'); // Frutiger® W04 56 Italic
      (window as any).MTFontIds.push('1572263'); // Frutiger® W04 65 Bold
      (window as any).MTFontIds.push('762077'); // Neue Frutiger® W10 Black
      (window as any).MTFontIds.push('762119'); // Neue Frutiger® W10 Extra Black

      const mtTracking = document.createElement('script');
      mtTracking.type = 'text/javascript';
      mtTracking.async = true;
      mtTracking.src = 'https://worldskills.org/application/themes/worldskills_org/js/mtiFontTrackingCode.js';

      (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(mtTracking);
    }
  }

  get buildMenuItems(): Array<MenuItem> {
    return menuConfig;
  }

  get isLoggedIn() {
    return this.auth.isLoggedIn() && this.currentUser != null;
  }

  login() {
    this.app.login();
  }

  logout() {
    this.app.logout();
  }

}
