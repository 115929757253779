<div *ngIf="event" class="d-flex mt-2 justify-content-between flex-wrap flex-md-nowrap align-items-center">
  <h1 class="h2">Results: {{ event.name }}</h1>
  <div class="btn-toolbar">
  </div>
</div>

<fieldset class="border p-2">
  <legend class="w-auto">Filters</legend>
  <div>
    Published: <input type="checkbox" [checked]="publish" (click)="publish = !publish; loadData();" />
  </div>
</fieldset>

<hr />

<div *ngIf="skillCollection && resultCollection">

  <div class="row" *ngFor="let skill of skillCollection.skills">
    <div class="col-sm-6">
      <h3 class="mt-0">
        {{ skill.number }} {{ skill.name.text }}
      </h3>
    </div>
    <div class="col-sm-6">
      <textarea style="width: 100%;" rows="8">{{ getResultsTextForSkill(skill) }}</textarea>
    </div>
  </div>
  <hr />

  <div class="row">
    <div class="col-sm-6">
      <h3 class="mt-0">
        Best Of Nation
      </h3>
    </div>
    <div class="col-sm-6">
      <textarea style="width: 100%;" rows="8">{{ getBestOfNationsText() }}</textarea>
    </div>
  </div>
  <hr />
  <div class="row">
    <div class="col-sm-6">
      <h3 class="mt-0">
        Albert-Vidal Award
      </h3>
    </div>
    <div class="col-sm-6">
      <textarea style="width: 100%;" rows="8">{{ getAlbertVidalText() }}</textarea>
    </div>
  </div>
</div>
