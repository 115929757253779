import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { Result } from '../result';
import { Event } from '../event';
import { Member } from '../member';
import { BaseSkill } from '../base-skill';
import { ResultService } from '../result.service';
import { Skill } from '../skill';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.css']
})
export class ResultsComponent implements OnInit {
  limit: number;
  offset: number;
  sort: string;
  totalCount: number;
  eventId: number;
  memberId: number;
  baseSkillId: number;
  skillId: number;
  results: Result[] = [];
  notes;
  emptyNoteIds;
  events: Event[] = [];
  members: Member[] = [];
  baseSkills: BaseSkill[] = [];
  skills: Skill[] = [];
  loading = true;

  constructor(
    private resultService: ResultService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe((params: Params) => {
      this.limit = +params['limit'] || 10;
      this.offset = +params['offset'] || 0;
      this.sort = params['sort'] || 'event_desc';
      this.eventId = +params['event'];
      this.memberId = +params['member'];
      this.baseSkillId = +params['base_skill'];
      this.skillId = +params['skill'];

      if (this.eventId) {
        this.getSkills(); // load skills
      }
    });
    this.resultService.getEvents().subscribe(events => {
      this.events = events.events;
    });
    this.resultService.getMembers().subscribe(members => {
      this.members = members.members;
    });
    this.resultService.getBaseSkills().subscribe(baseSkills => {
      this.baseSkills = baseSkills.base_skills;
    });
    this.getResults();
  }

  changeEvent($event): void {
    this.offset = 0; // reset offset
    this.router.navigate([], { queryParams: { offset: this.offset, event: this.eventId }, queryParamsHandling: 'merge' });
    this.getResults();
    if (!this.eventId) {
      this.skillId = null; // reset skill
    }
    this.getSkills();
  }

  changeMember($event): void {
    this.offset = 0; // reset offset
    this.router.navigate([], { queryParams: { offset: this.offset, member: this.memberId }, queryParamsHandling: 'merge' });
    this.getResults();
  }

  changeBaseSkill($event): void {
    this.offset = 0; // reset offset
    this.skillId = null; // reset skill
    this.router.navigate([], { queryParams: { offset: this.offset, skill: this.skillId, base_skill: this.baseSkillId }, queryParamsHandling: 'merge' });
    this.getResults();
  }

  changeSkill(skill): void {
    this.offset = 0; // reset offset
    if (skill) {
      this.baseSkillId = skill.base_id;
    } else {
      this.baseSkillId = null; // reset base skill
    }
    this.router.navigate([], { queryParams: { offset: this.offset, skill: this.skillId, base_skill: this.baseSkillId }, queryParamsHandling: 'merge' });
    this.getResults();
  }

  changeSort($event, sort) {
    $event.preventDefault();
    this.sort = sort;
    this.router.navigate([], { queryParams: { sort: this.sort }, queryParamsHandling: 'merge' });
    this.getResults();
  }

  changeLimit(limit): boolean {
    this.limit = limit;
    this.offset = 0; // reset offset
    this.router.navigate([], { queryParams: { limit: this.limit, offset: this.offset }, queryParamsHandling: 'merge' });
    this.getResults();
    return false;
  }

  getResults(): void {
    this.loading = true;
    this.resultService.getResults(this.offset, this.limit, this.sort, this.eventId, this.memberId, this.baseSkillId)
      .subscribe(results => {
        this.loading = false;
        this.totalCount = results.total_count;
        this.notes = results.notes.filter(note => note.comment.text);
        this.emptyNoteIds = results.notes.filter(note => !note.comment.text).map(note => note.id);
        this.results = results.results;
        this.results.forEach(result => {
          result.note_ids = result.note_ids.filter(note_id => !this.emptyNoteIds.includes(note_id));
        });
      });
  }

  getSkills() : void {
    if (this.eventId) {
      this.resultService.getSkills(this.eventId)
        .subscribe(skills => {
          this.skills = skills.skills;
          for (let skill of this.skills) {
            if (skill.base_id == this.baseSkillId) {
              this.skillId = skill.id;
              break;
            }
          }
        });
    } else {
      this.skills = [];
    }
  }

  previousPage(): boolean {
    this.offset = Math.max(this.offset - this.limit, 0);
    this.router.navigate([], { queryParams: { offset: this.offset }, queryParamsHandling: 'merge' });
    this.getResults();
    return false;
  }

  nextPage(): boolean {
    this.offset = Math.min(this.offset + this.limit, this.totalCount);
    this.router.navigate([], { queryParams: { offset: this.offset }, queryParamsHandling: 'merge' });
    this.getResults();
    return false;
  }
}
