import { HttpProgressEvent } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { EventModel } from '../../models/event-model';
import { SkillCollection } from '../../models/skill-collection';
import { ActivatedRoute } from '@angular/router';
import { EventsService } from '../../services/event.service';
import { SkillModel } from '../../models/skill-model';
import { ResultService } from '../../services/result.service';
import { UploadService } from '@worldskills/worldskills-angular-lib';
import { Skill } from 'src/app/skill';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.css']
})
export class EventDetailComponent implements OnInit {

  event: EventModel;

  uploadType: string;

  file: any;

  resultUploadProgress: HttpProgressEvent;

  resultUploading: boolean;

  skillCollection: SkillCollection;

  skillsProgress: number;

  deleteMsg: string;

  constructor(public route: ActivatedRoute, private events: EventsService, private results: ResultService,
              private uploader: UploadService) { }

  ngOnInit() {
    this.deleteMsg = '';
    this.skillsProgress = 0;
    this.skillCollection =  null;
    this.resultUploading = false;
    this.event = null;
    this.uploadType = 'best_of_nation';

    // this chain should be simplified at some point
    this.route.params.subscribe(
      params =>  {
        if (params.hasOwnProperty('id')) {
          const id = Number.parseInt(params.id, 10);
          this.events.get(id).subscribe(result => {
            this.event = result;
            this.events.listSkills(id).subscribe(skillResult => {
              this.skillCollection = skillResult;
              this.skillCollection.skills.forEach((skill, i) => {
                setTimeout(() => {
                  this.results.isSkillPublished(skill.id).subscribe(
                    boolResult => {
                      this.skillCollection.skills[i].published = boolResult.result;
                    }
                  );
                }, 50);
              });
            });
          });
        }
      }
    );
  }

  onFileSelect(files: FileList) {
    if (files.length > 0) {
      this.file = files[0];
    }
  }

  upload() {
    this.resultUploading = true;
    if (this.resultUploadProgress) {
      this.resultUploadProgress.loaded = 0;
    }
    const task = this.results.upload(this.uploadType, this.event.id, this.file);
    this.uploader.listen(
      task,
      (progress) => {
        this.resultUploadProgress = progress;
        console.log(progress);
      },
      (response) => {
        console.log(response);
      }
    );
  }

  removeAll() {
    this.deleteMsg = 'Deleteting All results';
    this.results.deleteResults(this.event.id).subscribe(result => {
      this.deleteMsg = 'done';
    });
  }

  removeForSkill(skill: SkillModel) {
    const msg = `Are you sure you want to remove the ${skill.published ? 'published' : ''} results for ${skill.name.text} ?`;
    if (confirm(msg)) {
      this.results.deleteResultsForSkill(this.event.id, skill.number).subscribe(result => {
        this.deleteMsg = 'done';
      });
    }
  }

  removeBestOfNations() {
    this.deleteMsg = 'Deleteting best of nations results';
    this.results.deleteResults(this.event.id, 'bon').subscribe(result => {
      this.deleteMsg = 'done';
    });
  }

  removeBestOfNationsForSkill(skill: SkillModel) {
    const msg = `Are you sure you want to remove the ${skill.published ? 'published' : ''} results for ${skill.name.text} ?`;
    if (confirm(msg)) {
      this.results.deleteResultsForSkill(this.event.id, skill.number, 'bon').subscribe(result => {
        this.deleteMsg = 'done';
      });
    }
  }

  togglePublish(skill: SkillModel) {
    if (skill.published) {
      if (confirm(`Are you sure you want to publish the results for ${skill.number} ${skill.name.text}`)) {
        this.results.publishSkillResult(skill.id).subscribe();
      } else {
        skill.published = !skill.published;
      }
    } else {
      if (confirm(`Are you sure you want to unpublish the results for ${skill.number} ${skill.name.text}`)) {
        this.results.unpublishSkillResult(skill.id).subscribe();
      }
      else {
        skill.published = !skill.published;
      }
    }
  }

  publishAll() {
    if (!confirm(`Are you sure you want to publish the results for all skills ?`)) {
      return;
    }
    if (this.skillCollection.skills.length === 0) {
      return;
    }
    let counter = 0;
    this.skillsProgress = 0;
    this.skillCollection.skills.forEach((skill, i) => {
      if (skill.published) {
        // assuming the last skill needs no action
        counter = counter + 1;
        this.updateSkillProgress(counter);
        return;
      }
      setTimeout(() => {
          this.results.publishSkillResult(skill.id).subscribe(result => {
            this.skillCollection.skills[i].published = true;
            counter = counter + 1;
            this.updateSkillProgress(counter);
          },
          error => {
            console.log(error);
            counter = counter + 1;
            this.updateSkillProgress(counter);
          });
      }, 50);
    });
  }

  updateSkillProgress(i: number) {
    this.skillsProgress = (i / (this.skillCollection.total_count - 1)) * 100;
  }

  unpublishAll() {
    if (!confirm(`Are you sure you want to unpublish the results for all skills ?`)) {
      return;
    }
    if (this.skillCollection.skills.length === 0) {
      return;
    }
    let counter = 0;
    this.skillsProgress = 0;
    this.skillCollection.skills.forEach((skill, i) => {
      if (!skill.published) {
        // assuming the last skill needs no action
        counter = counter + 1;
        this.updateSkillProgress(counter);
        return;
      }
      setTimeout(() => {
          this.results.unpublishSkillResult(skill.id).subscribe(result => {
            this.skillCollection.skills[i].published = false;
            counter = counter + 1;
            this.updateSkillProgress(counter);
          },
          error => {
            console.log(error);
            counter = counter + 1;
            this.updateSkillProgress(counter);
          });
      }, 50);
    });
  }


}
