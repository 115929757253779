<header>
  <ws-staging-banner *ngIf="environmentWarning" [message]="environmentWarning"></ws-staging-banner>
  <ws-puppeteer-banner [currentUser]="currentUser"></ws-puppeteer-banner>
  <ws-header
  [appName]="'Results'"
  [isLoggedIn]="isLoggedIn"
  [showLoginAndLogoutButtons]="showLoginAndLogoutButtons"
  [menuItems]="buildMenuItems"
  [dropDownMenuItems]="[]"
  [currentUser]="currentUser"
  (loginClick)="login()"
  (logoutClick)="logout()"
></ws-header>
</header>

<div class="container-fluid mt-3">

  <router-outlet></router-outlet>

</div>

<ws-footer
[isLoggedIn]="isLoggedIn"
[currentUser]="currentUser"
(loginClick)="login()"
(logoutClick)="logout()"
></ws-footer>
