import { Component, OnInit } from '@angular/core';
import { EventModel } from '../../models/event-model';
import { EventCollection } from '../../models/event-collection';
import { EventServiceParams } from '../../models/event-service-params';
import { BehaviorSubject, of } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { EventsService } from '../../services/event.service';

@Component({
  selector: 'app-list-events',
  templateUrl: './list-events.component.html',
  styleUrls: ['./list-events.component.css']
})
export class ListEventsComponent implements OnInit {

  collection: EventCollection;

  params: BehaviorSubject<EventServiceParams>;

  autoJumpToPage: boolean;

  itemsPerPage: number;

  nameFilter: string;

  typeFilter: string;

  typeList: string[];

  page = 1;

  constructor(private events: EventsService) { }

  ngOnInit() {
    this.typeList = [
      'competition',
      'general_assembly',
      'champions_forum',
      'conference',
      'meeting',
      'member_event'
    ];
    this.autoJumpToPage = true;
    this.itemsPerPage = 10;
    this.collection = {
      total_count: 0,
      events: []
    };
    this. params = new BehaviorSubject(new EventServiceParams());
    this.typeFilter = this.params.value.type || '';
    this.params.pipe(
      debounceTime(200),
      switchMap(
        p => {
          if (p) {
            return this.events.list(p.toParams()).pipe(
              tap((result: EventCollection) => {
                this.collection.events = result.events;
                this.collection.total_count = result.total_count;
              })
            );
          } else {
            return of({
              total_count: 0,
              events: []
            });
          }
        }
      )
    ).subscribe();
  }

  changePage(page: number) {
    this.page = page;
    const params = this.params.value;
    params.offset = this.calcOffset();
    this.params.next(params);
  }

  filterByName(e) {
    const params = this.params.value;
    if (params.name !== this.nameFilter) {
      params.name = this.nameFilter;
      params.offset = this.calcOffset();
      this.params.next(params);
    }
  }

  filterByType(e) {
    const params = this.params.value;
    if (params.type !== this.typeFilter) {
      params.type = this.typeFilter;
      params.offset = this.calcOffset();
      this.params.next(params);
    }
  }

  calcOffset(): number {
    if (this.page === 0) {
      this.page = 1;
    }
    return (this.page * this.itemsPerPage) - this.itemsPerPage;

  }
}
