<div class="alerts">
  <div class="alert alert-danger  alert-dismissible" role="alert">
    <h4 class="alert-heading">
      You do not have access to view this item.
    </h4>
    <span>
      Please try again, if this error persists contact your Technical Delegate or Member Organization.
      <span *ngIf="url">
        <br /><br />
        <strong>Addresss</strong>: <a href="{{ url }}">{{ url }}</a>
      </span>
      <span *ngIf="missingRole">
        <br />
        <strong>Reason</strong>: Missing permissions
      </span>
    </span>
  </div>
</div>
