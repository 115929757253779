import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../environments/environment';

export const routes = {
  authApi: `${environment.apiUrl}/auth`
};

export const appConfig = {
  notAuthorizedRoute: ['/not-authorized']
};

// general service configuration
export const serviceConfig = {
  // auth, results
  appCode: [2000],
  apiEndpoint: environment.apiUrl
};

// oauth client app configuration
export const oAuthConfig = {
  loginUrl: environment.loginUrl,
  redirectUri: environment.defaultUrl,
  userinfoEndpoint: `${routes.authApi}/users/loggedIn?show_child_roles=false&app_code=500`,
  clientId: environment.clientId,
  oidc: false
} as AuthConfig;

export const httpConfig = {
  // used to apply custom url parameter encoding for java services
  encoderUriPatterns: [],
  // used to automagically inject auth tokens in http requests
  authUriPatterns: [environment.apiUrl]
};
