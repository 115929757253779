import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ResultsComponent } from './results/results.component';
import { ListEventsComponent } from './admin/list-events/list-events.component';
import { EventDetailComponent } from './admin/event-detail/event-detail.component';
import { appRoles } from '../environments/environment.prod';
import { GuardService } from '@worldskills/worldskills-angular-lib';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';
import { environment } from '../environments/environment';
import { EventResultsComponent } from './admin/event-results/event-results.component';

const appCode = environment.appCode;
const adminRole = [
  { appCode, name: appRoles.admin }
];

const routes: Routes = [
  { path: '', redirectTo: '/results', pathMatch: 'full' },
  { path: 'not-authorized', component: NotAuthorizedComponent },
  { path: 'results', component: ResultsComponent },
  { path: 'admin/events', component: ListEventsComponent,  canActivate: [GuardService], data: { roles: adminRole } },
  { path: 'admin/events/:id', component: EventDetailComponent, canActivate: [GuardService], data: { roles: adminRole } },
  { path: 'admin/events/:id/results', component: EventResultsComponent, canActivate: [GuardService], data: { roles: adminRole } },

];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
