export const environment = {
  production: true,
  noindex: false,
  environmentWarning: false,
  sentryEnvironment: 'prod',
  apiUrl: 'https://api.worldskills.org',
  appCode: 2000,
  loginUrl: 'https://auth.worldskills.org/oauth/authorize',
  clientId: 'a816370092cd',
  defaultRoute: '/results',
  defaultUrl: 'https://results.worldskills.org/results',
  fontTrackingCode: true
};

export const appRoles = {
  admin: 'Admin',
};
