import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../environments/environment';

import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ResultList } from './result-list';
import { EventList } from './event-list';
import { MemberList } from './member-list';
import { BaseSkillList } from './base-skill-list';
import { SkillList } from './skill-list';

@Injectable({ providedIn: 'root' })
export class ResultService {

  private resultsUrl = environment.apiUrl + '/results';

  constructor(
    private http: HttpClient,
    @Inject(LOCALE_ID) private locale: string) { }

  getResults (offset, limit, sort, event, member, baseSkill): Observable<ResultList> {
    let url = `${this.resultsUrl}/?offset=${offset}&limit=${limit}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (event) {
      url += `&event=${event}`;
    }
    if (member) {
      url += `&member=${member}`;
    }
    if (baseSkill) {
      url += `&skill_base_id=${baseSkill}`;
    }
    url += `&l=${this.locale}`;
    return this.http.get<ResultList>(url).pipe(
      catchError(this.handleError('getResults', {results: [], notes: [], total_count: 0}))
    );
  }

  getEvents (): Observable<EventList> {
    const url = `${this.resultsUrl}/events`;
    return this.http.get<EventList>(url).pipe(
      catchError(this.handleError('getEvents', {events: []}))
    );
  }

  getMembers (): Observable<MemberList> {
    const url = `${this.resultsUrl}/members?l=en`;
    return this.http.get<MemberList>(url).pipe(
      catchError(this.handleError('getMembers', {members: []}))
    );
  }

  getBaseSkills (): Observable<BaseSkillList> {
    const url = `${this.resultsUrl}/base_skills?entity=1&l=en`;
    return this.http.get<BaseSkillList>(url).pipe(
      catchError(this.handleError('getBaseSkills', {base_skills: []}))
    );
  }

  getSkills (eventId: number): Observable<SkillList> {
    const url = `${this.resultsUrl}/events/${eventId}/skills?l=en`;
    return this.http.get<SkillList>(url).pipe(
      catchError(this.handleError('getSkills', {skills: []}))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
