import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';


@Component({
  selector: 'app-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.css']
})
export class NotAuthorizedComponent implements OnInit {

  url: string;
  missingRole: boolean;

  constructor() { }

  ngOnInit(): void {
    let error: any = sessionStorage.getItem('error');
    if (error !== null && error !== undefined) {
      error = JSON.parse(error);
      this.url = error.url || null;
      this.missingRole = error.missingRole || false;
    }


  }

}
