<div class="d-flex mt-2 justify-content-between flex-wrap flex-md-nowrap align-items-center">
  <h1 class="h2">Events</h1>
  <div class="btn-toolbar">
  </div>
</div>

<div class="table-responsive">
<table class="table table-striped border-top">
  <thead>
    <tr>
      <th scope="col" class="border-top-0">ID</th>
      <th scope="col" class="border-top-0">Name</th>
      <th scope="col" class="border-top-0">Type</th>
      <th scope="col" class="border-top-0">Start</th>
      <th scope="col" class="border-top-0">End</th>
      <th scope="col" class="border-top-0">Actions</th>
    </tr>
  </thead>
  <tfoot>
    <tr>
      <td colspan="6"></td>
    </tr>
  </tfoot>
  <tbody>
    <tr>
      <td></td>
      <td><input type="text" class="form-control" placeholder="All Events" [(ngModel)]="nameFilter" (ngModelChange)="filterByName($event)" /></td>
      <td>
        <select class="form-control" [(ngModel)]="typeFilter" (ngModelChange)="filterByType($event)">
          <option value="">All Types</option>
          <option *ngFor="let item of typeList" value="{{ item }}">{{ item }}</option>
        </select>
      </td>
      <td></td>
      <td></td>
    </tr>
    <tr *ngFor="let item of collection.events">
      <td>{{ item.id }}</td>
      <td>
        <a [routerLink]="['/admin/events', item.id]">{{ item.name }}</a>
      </td>
      <td>{{ item.type }}</td>
      <td>{{ item.start_date }}</td>
      <td>{{ item.end_date }}</td>
      <td>
        <a [routerLink]="['/admin/events', item.id, 'results']">View Results</a>
      </td>
    </tr>
  </tbody>
</table>
</div>

<ngb-pagination
  (pageChange)="changePage($event)"
  [collectionSize]="itemsPerPage" [(page)]="page" [boundaryLinks]="true" size="sm">
</ngb-pagination>
