import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UploadService } from '@worldskills/worldskills-angular-lib';
import { BoolResultModel } from '../models/bool-result-model';
import { ResultCollection } from '../models/result-collection';

@Injectable({
  providedIn: 'root'
})
export class ResultService {

  endpoint: string;
  constructor(private http: HttpClient, private uploader: UploadService) {
    this. endpoint = `${environment.apiUrl}/results`;
  }

  public upload(to: string, eventId: number, file: any): HttpRequest<FormData> {
    const data = new FormData();
    const url = `${this.endpoint}/events/${eventId}/${to}`;
    data.set('file', file);

    return this.uploader.prepareUpload(url, data, new HttpParams());
  }

  public getForEvent(id: number) {
    return this.http.get(`${this.endpoint}/events/${id}`);
  }

  public publishSkillResult(id: number) {
    return this.http.put(`${this.endpoint}/skills/${id}/publish`, {});
  }

  public unpublishSkillResult(id: number) {
    return this.http.put(`${this.endpoint}/skills/${id}/unpublish`, {});
  }

  public isSkillPublished(id: number) {
    return this.http.get<BoolResultModel>(`${this.endpoint}/skills/${id}/published`, {});
  }

  public deleteResults(id: number, type: string = 'all') {
    const headers = new HttpHeaders().set('Authorization', sessionStorage.getItem('token'));
    return this.http.delete(`${this.endpoint}/events/${id}/results?type=${type}`, { headers });
  }

  public deleteResultsForSkill(id: number, number: string, type: string = 'all') {
    const headers = new HttpHeaders().set('Authorization', sessionStorage.getItem('token'));
    return this.http.delete(`${this.endpoint}/events/${id}/results/${number}?type=${type}`, { headers });
  }

  public getResultsForEvent(eventId: number, published: boolean) {
    const url = `${this.endpoint}/events/${eventId}?published=${published ? 'true' : 'false'}`;
    const headers = new HttpHeaders().set('Authorization', sessionStorage.getItem('token'));
    return this.http.get<ResultCollection>(url, {});
  }
}
