import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { SkillCollection } from '../models/skill-collection';
import { EventModel } from '../models/event-model';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  endpoint: string;
  constructor(private http: HttpClient) {
    this.endpoint = `${environment.apiUrl}/events`;
  }

  public list(params: HttpParams) {
    return this.http.get(this.endpoint, { params });
  }

  public listSkills(eventId: number) {
    // hard-coded for now
    const params = new HttpParams().set('offset', '0').set('limit', '300');
    return this.http.get<SkillCollection>(`${this.endpoint}/${eventId}/skills`, { params });
  }

  public get(id: number) {
    return this.http.get<EventModel>(`${this.endpoint}/${id}`);
  }

  public getSkills(eventId: number, sort = 'sector_name_asc', limit = 100, type = 'official') {
    const url = `${this.endpoint}/${eventId}/skills?sort=${sort}&l=en&limit=${limit}&type=${type}`;
    const headers = new HttpHeaders().set('Authorization', sessionStorage.getItem('token'));
    return this.http.get<SkillCollection>(url, {});
  }
}
