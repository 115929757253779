import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ResultsComponent } from './results/results.component';
import { FooterComponent } from './footer/footer.component';
import { ListEventsComponent } from './admin/list-events/list-events.component';
import { EventDetailComponent } from './admin/event-detail/event-detail.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OAuthModule } from 'angular-oauth2-oidc';
import { WorldskillsAngularLibModule, WsHttpInterceptor } from '@worldskills/worldskills-angular-lib';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';
import { EventResultsComponent } from './admin/event-results/event-results.component';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

export const appTranslationConfig = TranslateModule.forRoot({
  loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient]
  },
  isolate: true // isolate property is the key point to remember/
});


@NgModule({
  declarations: [
    AppComponent,
    ResultsComponent,
    FooterComponent,
    ListEventsComponent,
    EventDetailComponent,
    NotAuthorizedComponent,
    EventResultsComponent
  ],
  imports: [
    BrowserModule,
    NgSelectModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    OAuthModule.forRoot(),
    WorldskillsAngularLibModule,
    appTranslationConfig
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: WsHttpInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
