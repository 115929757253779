<div *ngIf="event && skillCollection">


  <div class="d-flex mt-2 justify-content-between flex-wrap flex-md-nowrap align-items-center">
    <h1 class="h2">Event: {{ event.name }}</h1>
    <div class="btn-toolbar">
    </div>
  </div>
  <fieldset class="border p-2">
    <legend class="w-auto">Nav</legend>
    <nav>
      <nav class="menu" *ngIf="event">
        <ul>
          <li>
            <a [routerLink]="['/admin/events', event.id, 'results']">View Results</a>
          </li>
        </ul>
      </nav>
    </nav>
  </fieldset>
  <fieldset class="border p-2">
    <legend class="w-auto">Upload Results</legend>
    <div class="form-inline">
      <label class="sr-only" for="inlineFormInputGroupUsername2">Upload Type</label>
      <div class="input-group mb-2 mr-sm-2">
        <select class="form-control" id="inlineFormInputGroupUsername2" [(ngModel)]="uploadType">
          <option value="best_of_nation">Best Of Nations</option>
          <option value="cis">CIS</option>
        </select>
      </div>

      <label class="sr-only" for="inlineFormInputFile">File</label>
      <div class="input-group mb-2 mr-sm-2">
        <input class="form-control" type="file" id="inlineFormInputFile" (change)="onFileSelect($event.target.files)">
      </div>

      <button class="btn btn-primary" style="margin-top:-8px" (click)="upload()"><i
          class="fa fa-cloud-upload"></i>&nbsp;Upload</button>

      <progress *ngIf="resultUploading" style="height: 36px"
        [value]="resultUploadProgress ? resultUploadProgress.loaded : 0"
        [max]="resultUploadProgress ? resultUploadProgress.total : 100">
      </progress>
      <span *ngIf="resultUploadProgress && resultUploadProgress.loaded === resultUploadProgress.total">Done</span>
    </div>
  </fieldset>

  <fieldset class="border p-2">
    <legend class="w-auto">Delete Results</legend>
    <button class="btn btn-primary" style="margin-top:-8px" (click)="removeAll()"><i
        class="fa fa-remove"></i>&nbsp;Delete All</button>&nbsp;
    <button class="btn btn-primary" style="margin-top:-8px" (click)="removeBestOfNations()"><i
        class="fa fa-remove"></i>&nbsp;Delete Best Of Nations</button>
    <span>{{ deleteMsg }}</span>
  </fieldset>

  <fieldset class="border p-2">
    <legend class="w-auto">Skill Publish Settings</legend>
    <button class="btn btn-primary" style="margin-top:-8px" (click)="publishAll()"><i
        class="fa fa-cloud-upload"></i>&nbsp;Publish All</button>&nbsp;
    <button class="btn btn-primary" style="margin-top:-8px" (click)="unpublishAll()"><i
        class="fa fa-remove"></i>&nbsp;Unpublish All</button>

    <progress [value]="skillsProgress ? skillsProgress : 0" [max]="100">
    </progress>

    <div class="table-responsive">
      <table class="table table-striped border-top">
        <thead>
          <tr>
            <th scope="col" class="border-top-0">Name</th>
            <th scope="col" class="border-top-0">Published</th>
            <th scope="col" class="border-top-0">Actions</th>
          </tr>
        </thead>
        <tfoot>
          <tr>
            <td colspan="5"></td>
          </tr>
        </tfoot>
        <tbody>
          <tr *ngFor="let skill of skillCollection.skills">
            <td>{{ skill.name.text }} ({{ skill.number }})</td>
            <td>
              <input class="form-control" type="checkbox" [(ngModel)]="skill.published"
                (change)="togglePublish(skill)" />
            </td>
            <td>
              <button class="btn btn-primary" style="margin-top:-8px" (click)="removeForSkill(skill)"><i
                class="fa fa-remove"></i>&nbsp;Remove Results</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </fieldset>
</div>
