import { Injectable } from '@angular/core';
import { AuthService, GenericUtil, NgAuthService, User } from '@worldskills/worldskills-angular-lib';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private auth: NgAuthService, private oauth: OAuthService,  private wsiAuth: AuthService, private router: Router) { }

  login() {
    this.auth.login();
  }

  logout() {
    this.wsiAuth.logout().subscribe(
      result => {
        sessionStorage.clear();
        this.navigateHome();
      },
      error => {
        sessionStorage.clear();
        this.navigateHome();
      }
    );
  }

  navigateHome() {
    this.router.navigate([environment.defaultRoute]);
  }

  public handleUserProfile(user: User) {
    if (user && user.id > 0) {
      sessionStorage.setItem('token', this.oauth.authorizationHeader());
    } else {
      console.log(user);
    }
  }

  public loadUserProfile(): void {
    const currentUser = this.auth.currentUser.value;
    if (!GenericUtil.isNullOrUndefined(currentUser)) {
      this.handleUserProfile(currentUser);
    } else {
      this.auth.getLoggedInUser(true).subscribe(
        user => this.handleUserProfile(user)
      );
    }
  }

  public configureUser(): void {
    if (this.getUserToken()) {
      this.loadUserProfile();
    }
  }

  public getUserToken(): string {
    const token = this.oauth.authorizationHeader();
    return token === 'Bearer null' ? null : token;
  }
}
