
<form>
  <div class="row">
    <div class="col-sm-4">
      <div class="form-group">
        <label for="event" i18n>Competition</label>
        <ng-select [items]="events" bindLabel="name" bindValue="id" [(ngModel)]="eventId" (change)="changeEvent($event)" labelForId="event" name="event"></ng-select>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="form-group">
        <label for="competition" i18n>Member</label>
        <ng-select [items]="members" bindLabel="name.text" bindValue="id" [(ngModel)]="memberId" (change)="changeMember($event)" labelForId="member" name="member"></ng-select>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="form-group">
        <label i18n>Skill</label>
        <ng-select *ngIf="!eventId" [items]="baseSkills" bindLabel="name.text" bindValue="id" [(ngModel)]="baseSkillId" (change)="changeBaseSkill($event)" name="baseSkill"></ng-select>
        <ng-select *ngIf="eventId" [items]="skills" bindLabel="name.text" bindValue="id" [(ngModel)]="skillId" (change)="changeSkill($event)" name="skill"></ng-select>
      </div>
    </div>
  </div>

  <div class="table-responsive">
    <table class="table table-sm">
      <colgroup>
        <col width="5%">
        <col width="15%">
        <col width="20%">
        <col width="5%">
        <col width="20%">
        <col width="15%">
        <col width="20%">
      </colgroup>
      <thead>
        <tr>
          <th class="border-top-0">
            <a href="#" class="text-reset" (click)="changeSort($event, sort == 'position_desc' ? 'position_asc' : 'position_desc')">
              <span i18n>Rank</span>
              <span *ngIf="sort =='position_asc' || sort == 'position_desc'">&nbsp;<span class="sort-arrow-wrapper" [ngClass]="{'reversed': sort == 'position_desc'}"><span class="sort-arrow"></span></span></span>
            </a>
          </th>
          <th class="border-top-0" i18n>Medal</th>
          <th class="border-top-0" i18n>Competitor</th>
          <th class="border-top-0">
            <a href="#" class="text-reset" (click)="changeSort($event, sort == 'mark_desc' ? 'mark_asc' : 'mark_desc')">
              <span i18n>Result</span>
              <span *ngIf="sort =='mark_asc' || sort == 'mark_desc'">&nbsp;<span class="sort-arrow-wrapper" [ngClass]="{'reversed': sort == 'mark_desc'}"><span class="sort-arrow"></span></span></span>
            </a>
          </th>
          <th class="border-top-0">
            <a href="#" class="text-reset" (click)="changeSort($event, sort == 'event_desc' ? 'event_asc' : 'event_desc')">
              <span i18n>Competition</span>
              <span *ngIf="sort =='event_asc' || sort == 'event_desc'">&nbsp;<span class="sort-arrow-wrapper" [ngClass]="{'reversed': sort == 'event_desc'}"><span class="sort-arrow"></span></span></span>
            </a>
          </th>
          <th class="border-top-0" i18n>Member</th>
          <th class="border-top-0" i18n>Skill</th>
        </tr>
      </thead>
      <tbody *ngIf="!loading">
        <tr *ngFor="let result of results">
          <td>{{result.position}}.</td>
          <td><span *ngIf="result.medal" class="text-nowrap">{{result.medal.name.text}}</span></td>
          <td>
            <div *ngFor="let competitor of result.competitors" class="text-nowrap">
              {{competitor.first_name}} {{competitor.last_name}}
            </div>
          </td>
          <td>{{result.mark}} <sup *ngFor="let note of result.note_ids">{{ note }}</sup></td>
          <td class="text-nowrap">{{result.skill.event.name}}</td>
          <td class="text-nowrap">{{result.member.name.text}}</td>
          <td class="text-nowrap">{{result.skill.name.text}}</td>
        </tr>
      </tbody>
      <tbody *ngIf="loading">
        <tr><td colspan="7">&nbsp;</td></tr>
        <tr><td colspan="7">&nbsp;</td></tr>
        <tr><td colspan="7">&nbsp;</td></tr>
        <tr><td colspan="7">&nbsp;</td></tr>
        <tr><td colspan="7" class="text-center text-muted">Loading…</td></tr>
        <tr *ngFor="let result of [].constructor(limit - 5)"><td colspan="7">&nbsp;</td></tr>
      </tbody>
    </table>
  </div>
  
  <div class="row mt-2">
    <div class="col-sm-4">
      <div class="text-muted mb-3">
        <span *ngIf="results.length">
          <ng-container i18n>Results {{offset + 1}} to {{offset + results.length}} of total {{totalCount}}</ng-container>
          (<span class="dropdown">
            <a href="#" class="text-reset" data-toggle="dropdown" i18n>{{limit}}&nbsp;per&nbsp;page</a>
            <div class="dropdown-menu">
              <a class="dropdown-item" href="#" (click)="changeLimit(10)" i18n>10 per page</a>
              <a class="dropdown-item" href="#" (click)="changeLimit(50)" i18n>50 per page</a>
              <a class="dropdown-item" href="#" (click)="changeLimit(100)" i18n>100 per page</a>
            </div>
          </span>)
        </span>
        <span *ngIf="!loading && !results.length" i18n>No results</span>
      </div>
    </div>
    <div class="col-sm-8">
      <nav aria-label="Results navigation">
        <ul class="mt-0 pagination justify-content-end">
          <li class="page-item" [class.disabled]="offset < 1"><a class="page-link" href="" (click)="previousPage()" i18n>Previous page</a></li>
          <li class="page-item" [class.disabled]="offset + limit >= totalCount"><a class="page-link" href="" (click)="nextPage()" i18n>Next page</a></li>
        </ul>
      </nav>
    </div>
  </div>

</form>

<div class="mt-4" *ngIf="!loading && notes.length">
  <h6 class="text-muted mb-1"><small>Notes</small></h6>
  <p *ngFor="let note of notes" class="text-muted">
    <small><sup>{{note.id}}</sup> {{note.comment.text}}</small>
  </p>
</div>
